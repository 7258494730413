import React from 'react';
import Layout from '../../common/Layout';
import { useBuyCryptohelper } from './useBuyCryptohelper';

type Props = {};

const BuyCrypto: React.FC<Props> = () => {
  const {
    step,
    setStep,
    payload,
    setPayload,
    tokens,
    handleChange,
    exchange,
    btc,
    eth,
    sol,
    usdt,
    wave,
  } = useBuyCryptohelper();

  return (
    <Layout>
      <p className="text-center md:text-[50px] text-[30px] md:mt-[50px] mt-[25px] text-white font-bold">
        Buy Crypto
      </p>
      <h3 className="text-center text-[#727272] md:mt-[21px] md:mb-[50px] mb-[30px]">
        Buy digital asset via bank transfer
      </h3>

      <div className="mx-auto md:w-[540px] w-full bg-[#0D0D0D] rounded-md shadow-md mb-[40px]">
        <div className="p-[20px] flex gap-2 border-b-[1px] border-[#242424] rounded-t-[20px]">
          <div>
            <h3 className="text-[#c2c2c2] text-[13px]">Step {step}/4</h3>
            <p className="text-white font-medium">
              {step === 1 && 'Select Token'}
              {step === 2 && 'Amount'}
              {step === 3 && 'Review'}
              {step === 4 && 'Buy Crypto'}
            </p>
          </div>
        </div>
        {step === 1 && (
          <div>
            <div className="flex flex-col mt-[5px] gap-[0px] border-b-[1px] border-[#242424]">
              {tokens.map((token) => (
                <div
                  key={token.id}
                  onClick={() => setPayload({ ...payload, token: token.id })}
                  className={`flex gap-[8px] items-center px-[20px] py-[11px] cursor-pointer ${
                    payload?.token === token.id ? 'bg-[#071E16]' : ''
                  }`}
                >
                  <img
                    src={token.img}
                    alt={token.fullName}
                    className="h-[35px]"
                  />
                  <div className="flex flex-1 flex-row items-center justify-between">
                    <div>
                      <h3 className="text-white font-medium">{token.name}</h3>
                      <p className="text-[#474747]">{token.fullName}</p>
                    </div>
                    {payload?.token === token.id && <Check />}
                  </div>
                </div>
              ))}
            </div>

            <div className="px-[20px] my-[20px]">
              <h3 className="text-[#727272]">Network</h3>
              <select
                name="network"
                value={payload?.network}
                onChange={handleChange}
                className="mt-[10px] w-full p-3 rounded-md bg-[#040404] text-[#727272]"
              >
                <option value="">Select Network</option>
                <option value="eth">Ethereum</option>
                <option value="bsc">Binance Smart Chain</option>
                <option value="sol">Solana</option>
                <option value="polygon">Polygon</option>
              </select>

              <h3 className="mt-[10px] text-[#727272]">Wallet Address</h3>
              <input
                type="text"
                name="wallet"
                value={payload?.wallet}
                onChange={handleChange}
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
                placeholder="Enter your wallet address"
              />
              <button
                disabled={
                  !payload.network || !payload?.token || !payload?.wallet
                }
                onClick={() => setStep(2)}
                className="w-full mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
              >
                Next
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="px-[20px]">
            <h3 className="mt-[10px] text-[#727272]">Amount</h3>
            <input
              type="number"
              className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
              placeholder="Crypto"
              value={payload?.amount}
              onChange={(e) =>
                setPayload({ ...payload, amount: e?.target?.value })
              }
            />
            <div>
              <p className="text-white py-[20px]">
                You will recieve: - &#8358;
                {(+payload?.amount * 1700).toLocaleString()}
              </p>
            </div>
            <div className="flex p-[15px] rounded-md gap-[6px] items-center bg-[#5d5fa62a] border-dashed border-[2px] border-[#5D60A6]">
              <img src={exchange} alt="" className="h-[34px] w-[34px]" />
              <span className="text-[#5D60A6]">
                Buying rate for is &#8358;1700/$
              </span>
            </div>
            <div className="flex gap-[10px]">
              <button
                onClick={() => setStep(1)}
                className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
              >
                Back
              </button>
              <button
                disabled={!payload.amount}
                onClick={() => setStep(3)}
                className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
              >
                Next
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="p-[20px]">
            <p className="text-[#727272] mb-[10px]">Token Details</p>
            <div className="text-white mb-[1px] bg-[#242424] border-[1px] border-[#242424] rounded-md">
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>Token</span>
                <span className="flex gap-2 items-center">
                  {payload?.token}{' '}
                  {payload?.token === 'USDT' && (
                    <img src={usdt} className="h-[40px] w-[40px]" alt="usdt" />
                  )}
                  {payload?.token === 'Btc' && (
                    <img src={btc} className="h-[40px] w-[40px]" alt="btc" />
                  )}
                  {payload?.token === 'Wave' && (
                    <img src={wave} className="h-[40px] w-[40px]" alt="wave" />
                  )}
                  {payload?.token === 'Eth' && (
                    <img src={eth} className="h-[40px] w-[40px]" alt="eth" />
                  )}
                  {payload?.token === 'Sol' && (
                    <img src={sol} className="h-[40px] w-[40px]" alt="sol" />
                  )}
                </span>
              </div>
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>Network</span>
                <span className="flex gap-2 items-center">
                  {payload?.network}
                </span>
              </div>
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>Wallet Address: </span>
                <span className="flex gap-2 items-center">
                  {payload?.wallet}
                </span>
              </div>
            </div>
            <h3 className="mt-[10px] mb-[10px]">Amount</h3>
            <div className="text-white mb-[1px] bg-[#242424] border-[1px] border-[#242424] rounded-md">
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>Amount</span>
                <span className="flex gap-2 items-center">
                  {+payload?.amount.toLocaleString()} {payload?.network}
                </span>
              </div>
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>You will Recieve </span>
                <span className="flex gap-2 items-center">
                  {(+payload?.amount * 1700).toLocaleString()}
                </span>
              </div>
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>Network Fee </span>
                <span className="flex gap-2 items-center">500 NGN</span>
              </div>
              <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
                <span>Max Total </span>
                <span className="flex gap-2 items-center">
                  {(+payload?.amount * 1700 + 500).toLocaleString()} NGN
                </span>
              </div>
            </div>

            <div className="flex gap-[10px]">
              <button
                onClick={() => setStep(2)}
                className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
              >
                Back
              </button>
              <button
                onClick={() => setStep(4)}
                className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
              >
                Proceed
              </button>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="px-[20px]">
            <h3 className="text-white">
              You are about to sell 2,000 uSD Worth of USDT, Send 2,000 uSDT to
              the provided wallet within the time frame above
            </h3>
            <div className="flex p-[15px] mt-[25px] rounded-md gap-[6px] items-center bg-[#5d5fa62a] border-dashed border-[2px] border-[#5D60A6]">
              <h3 className="text-[#5D60A6]">
                Make sure you are sending on the solana network, transaction
                from any other network will result to loss of token
              </h3>
            </div>

            <div className="flex gap-[10px]">
              <button
                onClick={() => setStep(3)}
                className="w-3/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
              >
                Back
              </button>
              <button
                disabled={true}
                onClick={() => setStep(3)}
                className="w-9/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
              >
                I have transferred crypto
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BuyCrypto;

const Check = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-6 text-[#51B590]"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
);
