import React from "react";
import Layout from "../../common/Layout";
import { useGetMeQuery } from "../../hooks/Auth/useGetMeQuery";

type Props = {};

const Profile = (props: Props) => {
  const { data: user } = useGetMeQuery();
  return (
    <Layout>
      <div className="w-[450px] mx-auto  mt-[80px]">
        <div className="flex items-center justify-between mb-[25px]">
          <div className="flex gap-[10px] items-center">
            <img
              src="https://placehold.co/150.png"
              alt="user avatar"
              className="h-[45px] w-[45px] rounded-full"
            />
            <div>
              <h3 className="text-white font-medium text-[20px]">
                Hello{user?.data?.firstName && ", " + user?.data?.firstName}
              </h3>
              <p className="text-[#777777]">
                {user?.data?.emailAddress && user?.data?.emailAddress}
              </p>
            </div>
          </div>
          <button className="bg-gradient-to-b from-[#686DEA] to-[#4147c1] text-white font-bold py-2 px-4 rounded-md">
            Edit Profile
          </button>
        </div>
        <div className="bg-[#0D0D0D]">
          <div className="p-[20px] flex gap-2 border-b-[1px] border-[#242424] rounded-t-[20px]">
            <p className="text-white font-medium">Your Profile</p>
          </div>

          <div>
            <div className="flex gap-[5px] px-[20px] py-[20px]">
              <div className="w-6/12">
                <h3 className="text-[#727272]">First Name</h3>
                <input
                  type="text"
                  className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272]"
                  placeholder="First Name"
                />
              </div>
              <div className="w-6/12">
                <h3 className="text-[#727272]">Last Name</h3>
                <input
                  type="text"
                  className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272]"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="px-[20px] pb-[20px]">
              <h3 className="text-[#727272]">Email</h3>
              <input
                type="email"
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272]"
                placeholder="Email"
              />
            </div>
            <div className="px-[20px] pb-[20px]">
              <h3 className="text-[#727272]">Phone Number</h3>
              <input
                type="text"
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272]"
                placeholder="+234"
              />
            </div>
            <div className="px-[20px] pb-[20px]">
              <h3 className="text-[#727272]">Gender</h3>
              <select
                name=""
                id=""
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272]"
              >
                <option value="">Select option</option>
                <option value="">Male</option>
                <option value="">Female</option>
                <option value="">Others</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
