import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";
import { message } from "antd";

export const useConfirmLoginMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: { id: number; otp: string }) =>
      axiosInstance.post("/auth/verify-otp", payload).then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data?.message);
        localStorage.setItem("FLYNTPAY_USER_TOKEN", data?.data?.token);
        navigate("/app/dashboard");
      },
      onError: (error: any) => {
        console.error(error);
      },
    }
  );
};
