import React from 'react';
import Layout from '../../common/Layout';

type Props = {};

const More = (props: Props) => {
  return <Layout>More</Layout>;
};

export default More;
