import React from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const KycVerification = (props: Props) => {
  const id = require('../../assets/images/id.png');

  return (
    <div className="bg-[#281D00] rounded-md p-[14px] flex justify-between items-center mt-[20px] md:text-[16px] text-[12px]">
      <span className="flex gap-[10px] items-center">
        <img src={id} alt="icon" className="h-[25px]" />
        <span className="text-[#FFCF55]">Complete your KYC</span>
      </span>
      <Link to="/app/kyc" className="text-[#FFCF55] underline">
        Click to proceed
      </Link>
    </div>
  );
};

export default KycVerification;
