import React from 'react';
import Layout from '../../common/Layout';
import { useSellGiftcardsHelper } from './useSellGiftcardsHelper';

type Props = {};

const SellGiftcards = (props: Props) => {
  const { step, setStep, payload, setPayload, items, exchange, handleChange } =
    useSellGiftcardsHelper();
  return (
    <Layout>
      <p className="text-center text-[50px] mt-[50px] text-white font-bold">
        Sell Giftcards
      </p>
      <h3 className="text-center text-[#727272] mt-[21px] mb-[50px]">
        Sell your giftcards with ease
      </h3>
      <div className="mx-auto w-[540px] bg-[#0D0D0D] rounded-md">
        <div className="p-[20px] flex gap-2 border-b-[1px] border-[#242424] rounded-t-[20px]">
          <div>
            <h3 className="text-[#c2c2c2] text-[13px]">Step {step}/4</h3>
            <p className="text-white font-medium">
              {step === 1 && 'Select Giftcard'}
              {step === 2 && 'Additional Information'}
              {step === 3 && 'Upload Giftcard Image'}
              {step === 4 && 'Buy Crypto'}
            </p>
          </div>
        </div>
        <div className="p-[20px]">
          {step === 1 && (
            <div className="grid grid-cols-3 gap-[10px]">
              {items.map((it: any) => (
                <div
                  key={it?.id}
                  className="cursor-pointer mb-3"
                  onClick={() => {
                    setPayload({
                      ...payload,
                      name: it?.fullName,
                      img: it?.img,
                    });
                    setStep(2);
                  }}
                >
                  <img
                    src={it?.img}
                    alt=""
                    className={`w-full h-[97px] ${
                      payload?.name === it?.id &&
                      'border-[4px] border-[#686DEA] rounded-[12px]'
                    }`}
                  />
                  <h3 className="text-white mt-2">{it?.name}</h3>
                </div>
              ))}
            </div>
          )}

          {step === 2 && (
            <div>
              <div className="flex items-center gap-[10px] p-[10px] border-[#242424] border-[1px] rounded-md">
                <img src={payload?.img} className="h-[51px] w-[74px]" alt="" />
                <p className="text-white">{payload?.name}</p>
              </div>
              <h3 className="mt-[10px] text-[#727272]">Subcategory</h3>
              <select
                name="Subcategory"
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
              >
                <option>Select Name</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <h3 className="mt-[10px] text-[#727272]">Total Amount</h3>
              <input
                type="text"
                name="totalAmount"
                value={payload?.totalAmount}
                onChange={handleChange}
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
                placeholder="USD"
              />
              <h3 className="mt-[10px] text-[#727272]">Quantity</h3>
              <div className="flex justify-between items-center mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]">
                <span>{payload?.quantity}</span>
                <span className="flex gap-[10px] items-center">
                  <button
                    disabled={payload?.quantity === 1}
                    onClick={() =>
                      setPayload({
                        ...payload,
                        quantity: payload?.quantity - 1,
                      })
                    }
                    className={`${payload?.quantity === 1 && 'opacity-50'}`}
                  >
                    <Minus />
                  </button>
                  <button
                    disabled={payload?.quantity === 9}
                    onClick={() =>
                      setPayload({
                        ...payload,
                        quantity: payload?.quantity + 1,
                      })
                    }
                    className={`${payload?.quantity === 9 && 'opacity-50'}`}
                  >
                    <Plus />
                  </button>
                </span>
              </div>
              <div>
                <p className="text-white mt-4">
                  You will recieve: - &#8358;
                  {(+payload?.totalAmount * 1700).toLocaleString()}
                </p>
                <div className="mt-4 flex p-[15px] rounded-md gap-[6px] items-center bg-[#5d5fa62a] border-dashed border-[2px] border-[#5D60A6]">
                  <img src={exchange} alt="" className="h-[34px] w-[34px]" />
                  <span className="text-[#5D60A6]">
                    Buying rate for is &#8358;1700/$
                  </span>
                </div>
              </div>
              <div className="flex gap-[10px]">
                <button
                  onClick={() => setStep(1)}
                  className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
                >
                  Back
                </button>
                <button
                  onClick={() => setStep(3)}
                  disabled={!payload.totalAmount || !payload?.Subcategory}
                  className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 3 && <div></div>}
        </div>
      </div>
    </Layout>
  );
};

export default SellGiftcards;

const Plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="size-10 bg-[#242424] p-2 rounded-md"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

const Minus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="size-10 bg-[#242424] p-2 rounded-md"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
    </svg>
  );
};
