import React from "react";
import Layout from "../../common/Layout";
import { Link } from "react-router-dom";
import { useGetMeQuery } from "../../hooks/Auth/useGetMeQuery";

type Props = {};

const KYC = (props: Props) => {
  const { data } = useGetMeQuery();
  return (
    <Layout>
      <div className="w-[450px] mx-auto  mt-[80px]">
        <div className="flex items-center justify-between mb-[25px]">
          <div className="flex gap-[10px] items-center">
            <img
              src="https://placehold.co/150.png"
              alt="user avatar"
              className="h-[45px] w-[45px] rounded-full"
            />
            <div>
              <h3 className="text-white font-medium text-[20px]">
                Hello{data?.data?.firstName && ", " + data?.data?.firstName}
              </h3>
              <p className="text-[#777777]">
                {data?.data?.emailAddress && data?.data?.emailAddress}
              </p>
            </div>
          </div>
          <Link
            to="/app/profile"
            className="bg-gradient-to-b from-[#686DEA] to-[#4147c1] text-white font-bold py-2 px-4 rounded-md"
          >
            Edit Profile
          </Link>
        </div>
        <div className="bg-[#0D0D0D]">
          <div className="p-[20px] flex gap-2 border-b-[1px] border-[#242424] rounded-t-[20px]">
            <p className="text-white font-medium">KYC Verification</p>
          </div>

          <div>
            <div className="px-[20px] pb-[20px]">
              <h3 className="text-[#727272]">Document Type</h3>
              <select
                name=""
                id=""
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272]"
              >
                <option value="">Select option</option>
                <option value="">NIN</option>
                <option value="">Voter's Card</option>
                <option value="">International Passport</option>
              </select>
              <button
                //   disabled={!payload.amount}
                //   onClick={() => setStep(3)}
                className="w-full mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default KYC;
