import { useState, ChangeEvent } from 'react';

const exchange = require('../../assets/images/Rates.png');
const btc = require('../../assets/images/btcc.png');
const bnb = require('../../assets/images/bnb.png');
const eth = require('../../assets/images/eth.png');
const sol = require('../../assets/images/sol.png');
const usdt = require('../../assets/images/usdt.png');
const wave = require('../../assets/images/usdt.png');

export const useBuyCryptohelper = () => {
  const [step, setStep] = useState<number>(1);
  const [payload, setPayload] = useState({
    token: '',
    network: '',
    wallet: '',
    amount: '',
  });

  const tokens = [
    { id: 'btc', name: 'BTC', fullName: 'Bitcoin', img: btc },
    { id: 'bnb', name: 'BNB', fullName: 'Binance Coin', img: bnb },
    { id: 'sol', name: 'SOL', fullName: 'Solana', img: sol },
    { id: 'usdt', name: 'USDT', fullName: 'Tether USD', img: usdt },
    { id: 'eth', name: 'ETH', fullName: 'Ethereum', img: eth },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  return {
    step,
    setStep,
    payload,
    setPayload,
    tokens,
    handleChange,
    exchange,
    btc,
    bnb,
    eth,
    sol,
    usdt,
    wave,
  };
};
