/* eslint-disable react/display-name */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const stringifyToken = localStorage.getItem('FLYNTPAY_USER_TOKEN');
    // const parsedToken = stringifyToken ? JSON.parse(stringifyToken) : null;

    useEffect(() => {
      if (!stringifyToken) {
        navigate('/');
        // toast('Unauthorized, proceed to sign in');
        // return null;
      }
    }, [navigate, stringifyToken]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
