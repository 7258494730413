// import { message } from 'antd';
import axios from 'axios';
// import toast from 'react-hot-toast';
// import { useNavigate } from 'react-router-dom';
// import { handleError } from '../hooks/useErrorCatcher';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const stringifyToken = localStorage.getItem('USER_TOKEN');

      const tokenParsed = stringifyToken
        ? JSON.parse(stringifyToken).accessToken
        : null;

      if (tokenParsed && typeof tokenParsed === 'string' && config.headers) {
        config.headers.authorization = `Bearer ${tokenParsed}`;
      }
    } catch (error) {
      // console.error('Error parsing token:', error);
      // message.error('Failed to parse authentication token.')
      // toast.error('Failed to parse authentication token.');
    }
    return config;
  },
  async (error) => {
    // message.error('Request error: ' + error.message)
    // toast.error('Request error: ' + error.message);
    console.log('==> REFRESHHH <==', error);
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const navigate = useNavigate();
//     handleError(error, navigate);

//     return Promise.reject(error);
//   }
// );
