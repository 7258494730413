import React from 'react';
import Layout from '../../common/Layout';

type Props = {};

const SellCrypto = (props: Props) => {
  return (
    <Layout>
      <p className="text-center text-[50px] mt-[50px] text-white font-bold">
        Sell Crypto
      </p>
      <h3 className="text-center text-[#727272] mt-[21px] mb-[50px]">
        Sell your crypto with ease
      </h3>
      <div className="mx-auto w-[540px] bg-[#0D0D0D] rounded-md">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, dolor
        labore doloremque, itaque, accusantium iste optio earum pariatur ipsum
        molestias non quaerat eligendi distinctio placeat. Harum molestias
        dignissimos dolores molestiae.
      </div>
    </Layout>
  );
};

export default SellCrypto;
