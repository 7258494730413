import React from 'react';

import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/queryClient';
import Dashboard from './pages/Dashboard/Dashboard';
import BuyCrypto from './pages/BuyCrypto/BuyCrypto';
import SellCrypto from './pages/SellCrypto/SellCrypto';
import SellGiftcards from './pages/SellGiftcards/SellGiftcards';
import More from './pages/More/More';
import Profile from './pages/Profile/Profile';
import KYC from './pages/KYC/KYC';
import SignUp from './pages/Auth/SignUp/SignUp';
import Login from './pages/Auth/Login';
// import Auth from './pages/Auth/Auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login/>,
  },{
    path: '/create',
    element: <SignUp/>,
  },
  {
    path: '/app/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/app/buy-crypto',
    element: <BuyCrypto />,
  },
  {
    path: '/app/sell-crypto',
    element: <SellCrypto />,
  },
  {
    path: '/app/sell-giftcards',
    element: <SellGiftcards />,
  },
  {
    path: '/app/more',
    element: <More />,
  },
  {
    path: '/app/profile',
    element: <Profile />,
  },
  {
    path: '/app/kyc',
    element: <KYC />,
  },
  { path: '*', element: <p>ERROR</p> },
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
