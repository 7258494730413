import { useQuery } from "react-query";
import { axiosInstance } from "../../utils/axiosInstance";

export const useGetMeQuery = () => {
  const token = localStorage.getItem("FLYNTPAY_USER_TOKEN");

  return useQuery({
    queryKey: "gettutor",
    queryFn: async () => {
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await axiosInstance.get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {},
    onError: (error) => {
      console.error("Error fetching user data:", error);
    },
  });
};
