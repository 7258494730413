import React from 'react';
import Layout from '../../common/Layout';
import Portfolio from './Portfolio';
import QuickActions from './QuickActions';
import RecentActivities from './RecentActivities';
import UserCard from './UserCard';
import KycVerification from './KycVerification';

type Props = {};

const Dashboard = (props: Props) => {
  return (
    <Layout>
      <UserCard />
      <Portfolio />
      <KycVerification />
      <QuickActions />
      <RecentActivities />
    </Layout>
  );
};

export default Dashboard;
