import React from "react";
import { Link } from "react-router-dom";

const QuickActions = () => {
  const Buy = require("../../assets/images/Buy.png");
  const Sell = require("../../assets/images/Sell.png");
  const SellGiftcards = require("../../assets/images/SellGiftcards.png");
  const Rates = require("../../assets/images/Rates.png");

  return (
    <div>
      <h3 className="text-white font-medium mt-[40px] md:text-[16px] text-[11px]">Quick Actions</h3>
      <div className="mt-[15px] grid grid-cols-2 md:grid-cols-4 gap-[11px]">
        <Card
          to={"app/buy-crypto"}
          image={Buy}
          title={"Buy Crypto"}
          description={"Buy crypto via bank transfer"}
        />
        <Card
          to={"app/sell-crypto"}
          image={Sell}
          title={"Sell Crypto"}
          description={"Sell Crypto with ease"}
        />
        <Card
          to={"app/sell-giftcards"}
          image={SellGiftcards}
          title={"Sell Giftcards"}
          description={"Sell Giftcard with ease"}
        />
        <Card
          to={"app/exchange-rates"}
          image={Rates}
          title={"Exchange Rate"}
          description={"Our trading rate for alll token"}
        />
      </div>
    </div>
  );
};

export default QuickActions;

const Card = ({
  to,
  image,
  title,
  description,
}: {
  to: string;
  image: string;
  title: string;
  description: string;
}) => {
  return (
    <Link
      to={`/${to}`}
      className="col-span-1 flex border-[1px] border-[#242424] bg-[#000] rounded-md gap-[10px] md:p-[15px] p-[10px] items-center"
    >
      <img src={image} alt="" className="md:h-[38px] h-[25px]" />
      <div>
        <h3 className="text-white font-medium md:text-[16px] text-[11px]">
          {title}
        </h3>
        <p className="text-[#727272] md:text-[16px] text-[11px]">
          {description}
        </p>
      </div>
    </Link>
  );
};
